<template lang="pug">
div(v-show='price > 5000' :data-page-type='pageType' :data-amount='price' class='affirm-as-low-as text-sm text-black')
</template>

<script setup lang="ts">
const { $affirm } = useNuxtApp()

const { price = 0, pageType = 'product' } = defineProps<{
  price: number
  pageType: 'product' | 'cart' | 'category'
  learnMore?: boolean
}>()

watch(
  () => price,
  async () => {
    await nextTick()
    $affirm.refresh()
  }
)

onMounted(() => {
  $affirm.load()
  $affirm.refresh()
})
</script>
